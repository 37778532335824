import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import ArchiveIcon from "@mui/icons-material/Archive";
import NotificationsIcon from "@mui/icons-material/Notifications";
import GroupIcon from "@mui/icons-material/Group";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import HandshakeIcon from "@mui/icons-material/Handshake";
import {
  AccountCircleOutlined,
  AddModeratorOutlined,
  Dashboard,
  FolderSharedOutlined,
  NotificationsActiveOutlined,
  PaidOutlined,
  PeopleAltOutlined,
} from "@mui/icons-material";
const icons = {
  Dashboard,
  FolderSharedOutlined,
  PeopleAltOutlined,
  AddModeratorOutlined,
  PaidOutlined,
  NotificationsActiveOutlined,
  AccountCircleOutlined,
};

const dashboard = {
  id: "group-dashboard",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/admin/dashboard",
      icon: icons.Dashboard,
      breadcrumbs: false,
    },
    {
      id: "Registers",
      title: "Registers",
      type: "item",
      url: "/admin/registers",
      icon: AnalyticsIcon,
      breadcrumbs: false,
    },
    {
      id: "Sites",
      title: "Sites",
      type: "item",
      url: "/admin/sites",
      icon: AccountTreeIcon,
      breadcrumbs: false,
    },
    {
      id: "UserList",
      title: "User List",
      type: "item",
      url: "/admin/user-list",
      icon: GroupIcon,
      breadcrumbs: false,
    },
    {
      id: "notifications",
      title: "Notifications",
      type: "item",
      url: "/admin/notifications",
      icon: NotificationsIcon,
      breadcrumbs: false,
    },
    // {
    //   id: "Archived",
    //   title: "Archived",
    //   type: "item",
    //   url: "/admin/archived",
    //   icon: ArchiveIcon,
    //   breadcrumbs: false,
    // },
    {
      id: "Subscribers",
      title: "Subscribers",
      type: "item",
      url: "/admin/subscribers",
      icon: HandshakeIcon,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
