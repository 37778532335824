// project import
import Navigation from './Navigation';
import SimpleBar from '../../../../components/third-party/SimpleBar';
import { useNavigate } from 'react-router-dom';
import { getTokenType } from '../../../../../helper/Utility';

// ==============================|| DRAWER CONTENT ||============================== //

export default function DrawerContent() {
  const navigate = useNavigate();
  const handleLogout = (type) => {
    localStorage.removeItem(getTokenType(type));
    navigate("/admin/login");
  };

  return (
    <>
      <SimpleBar className="bg_side" sx={{ '& .simplebar-content': { display: 'flex', flexDirection: 'column' } }}>
        <Navigation />

        <div className='logour_side' onClick={() => handleLogout("admin")}>
          <button><i class="fa-solid fa-arrow-right-from-bracket me-1"></i>Logout</button>
        </div>
      </SimpleBar>
    </>
  );
}
