import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Spinner } from 'react-bootstrap';
import { toastifyError, toastifySuccess } from '../../helper/Utility';
import { APICALL } from '../../helper/api/api';

const ConfirmationModal = ({ deleteModal, setDeleteModal, id, type, onDeleteSuccess }) => {
    const [isSubmit, setIsSubmit] = useState(false);

    const handleDelete = async () => {
        setIsSubmit(true);
        try {
            const res = await APICALL("/admin/softDelete", 'post', { id: id, type: type });

            if (res?.status) {
                setIsSubmit(false);
                setDeleteModal(false);
                onDeleteSuccess();
                toastifySuccess(res?.message);
            } else {
                setDeleteModal(false);
                setIsSubmit(false);
                toastifyError(res?.message || "Delete failed");
            }
        } catch (error) {
            setIsSubmit(false);
            toastifyError("Something went wrong!");
        }
    };

    return (
        <>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={deleteModal}
                className='modal-all'
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Delete Confirmation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Are you sure you want to delete this ?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-secondary' onClick={() => setDeleteModal(false)}>Close</button>
                    {isSubmit
                        ? <button className='btn btn-danger'>
                            <Spinner style={{ height: '20px', width: '20px' }} />
                        </button>
                        : <button className='btn btn-danger' onClick={handleDelete}>Delete</button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ConfirmationModal;
