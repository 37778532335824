import { auth } from '../../helper/Utility';
import dashboard from './dashboard';

// Filter out the "Subscribers" item if userRole is "subscriber"
const userRole = auth('admin')?.user_role;

// Dynamically filter dashboard children
if (userRole === "subscriber") {
  dashboard.children = dashboard.children.filter(
    (item) => item.id !== "Subscribers"
  );
}

// ==============================|| MENU ITEMS ||============================== //
const menuItems = {
  items: [dashboard],
};

export default menuItems;
