import React, { createContext, useContext, useState, useEffect } from 'react';
import { APICALL } from '../api/api';
import { auth } from '../Utility';
const ContextData = createContext();
export const useDataContext = () => useContext(ContextData);
export const ContextProvider = ({ children }) => {

    useEffect(() =>{
        getPermision()
    },[])
    const [permisionData, setPermisionData] = useState(null)
    const getPermision = async () => {
        try {
            const res = await APICALL('/admin/getRoleById')
            if (res?.status) {
                console.log("ContextProvider", res)
                setPermisionData(res?.data)
            } else {
                setPermisionData(null)
            }
        } catch (error) {
            setPermisionData(null)
        }
    }
    
    return (
        <ContextData.Provider value={{getPermision, permisionData}}>
            {children}
        </ContextData.Provider>
    );
};