import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { APICALL } from "../../../helper/api/api";
import { toastifyError, toastifySuccess } from "../../../helper/Utility";
import AdminLoader from "../../components/AdminLoader"; 

const ChangePassword = () => {
    const [formData, setFormData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.oldPassword) newErrors.oldPassword = 'Old password is required';
        if (!formData.newPassword) newErrors.newPassword = 'New password is required';
        if (!formData.confirmPassword) newErrors.confirmPassword = 'Confirm password is required';
        if (formData.newPassword !== formData.confirmPassword)
            newErrors.confirmPassword = 'New password and confirm password do not match';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Returns true if no errors
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            try {
                const res = await APICALL('/admin/changeAdminPassword', 'post', formData)
                if (res?.status) {
                    setFormData({
                        oldPassword: '',
                        newPassword: '',
                        confirmPassword: ''
                    });
                    setErrors({});
                    setLoading(false);
                    toastifySuccess(res?.message)
                } else {
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                if (error.response) {
                    const message = error.response.data?.message || 'An unknown error occurred';

                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        oldPassword: message
                    }));
                }
            }
        }
    };

    return (
        <>
            <Row>
                <Col md={3}>
                    <div className="bredcrumb_img">
                        <ul className="p-0">
                            <li>
                                <Link to="/">
                                    <i class="fa-solid fa-house me-2"></i>Home /
                                </Link>
                            </li>
                            <li>
                                <Link className="active" to="/">
                                    Change Password
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <Typography className="top_page" variant="h5">
                        Change Password
                    </Typography>
                </Col>
            </Row>

            {loading ? <AdminLoader /> :
                <Form onSubmit={handleSubmit}>
                    <Row className="cutoms-login-artist mt-5">
                        <Col md={4}>
                            <Form.Group className="mb-3" controlId="oldPassword">
                                <Form.Label>Old Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="oldPassword"
                                    placeholder="Old Password"
                                    value={formData.oldPassword}
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.oldPassword}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.oldPassword}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col md={4}>
                            <Form.Group className="mb-3" controlId="newPassword">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="newPassword"
                                    placeholder="New Password"
                                    value={formData.newPassword}
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.newPassword}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.newPassword}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col md={4}>
                            <Form.Group className="mb-3" controlId="confirmPassword">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="confirmPassword"
                                    placeholder="Confirm Password"
                                    value={formData.confirmPassword}
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.confirmPassword}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.confirmPassword}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col md={12} className="text-end">
                            <Button type="submit" className="global_btn" variant="primary">
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Form>}
        </>
    );
}

export default ChangePassword