import { lazy } from "react";
import Loadable from "../components/Loadable";
import Dashboard from "../layout/Dashboard";
import ChangePassword from "../pages/authentication/ChangePassword";
import InspectionList from "../pages/sites/InspectionList";
import Notification from "../layout/Dashboard/Header/HeaderContent/Notification";
import NotificationsList from "../pages/notification/NotificationsList";
import SubscribersList from "../pages/subscribers/subscribersList";

const DashboardDefault = Loadable(
  lazy(() => import("../pages/dashboard/index"))
);
const UserList = Loadable(lazy(() => import("../pages/userlist/UserList")));
const Sites = Loadable(lazy(() => import("../pages/sites/Sites")));
const Registers = Loadable(lazy(() => import("../pages/Registers/Registers")));
const Archived = Loadable(lazy(() => import("../pages/Archived/Archived")));

const MainRoutes = {
  path: "/",
  element: <Dashboard />,
  children: [
    {
      path: "/",
      element: <DashboardDefault />,
    },
    {
      path: "/dashboard",
      element: <DashboardDefault />,
    },
    {
      path: "/user-list",
      element: <UserList />,
    },

    {
      path: "/sites",
      element: <Sites />,
    },

    {
      path: "/change-password",
      element: <ChangePassword />,
    },

    {
      path: "/Registers",
      element: <Registers />,
    },

    {
      path: "/Archived",
      element: <Archived />,
    },

    {
      path: "/inspection-list",
      element: <InspectionList />,
    },

    {
      path: "/notifications",
      element: <NotificationsList />,
    },
    {
      path: "/subscribers",
      element: <SubscribersList />,
    },
  ],
};

export default MainRoutes;
