import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, TextField } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  Checkbox,
} from "@mui/material";
import { Col, Form, Modal, Row, Dropdown, Spinner } from "react-bootstrap";
import {
  ArrowDownward,
  ArrowUpward,
  Edit,
  MoreVert,
  SwapVert,
} from "@mui/icons-material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import KeyIcon from '@mui/icons-material/Key';
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import { APICALL } from "../../../helper/api/api";
import AdminLoader from "../../components/AdminLoader";
import {
  apiBaseURL,
  toastifyError,
  toastifySuccess,
} from "../../../helper/Utility";
import ConfirmationModal from "../../components/ConfirmationModal";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UserList = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [password, setPassword] = useState(null);

  const [showModal2, setShowModal2] = useState(false);

  const handleCloseModal2 = () => setShowModal2(false);
  const handleShowModal2 = () => setShowModal2(true);

  const handleClose = () => setShow(false);

  const handleShow = () => {
    setIsEditMode(false);
    setFormData({ firstName: "", lastName: "", email: "" });
    setShow(true);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [personName, setPersonName] = React.useState([]);

  const handleChangeRegister = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const [fileName, setFileName] = useState("");

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setFileName(e.target.files[0].name);
    }
  };

  const getUsersList = async () => {
    setLoading(true);
    try {
      const res = await APICALL("/admin/getAllSubscriber", "post", {});
      if (res?.status) {
        setLoading(false);
        setUsers(res?.data);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [searchTerm, setSearchTerm] = useState("");

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const filteredUsers = users.filter((user) =>
    Object.keys(user).some((key) =>
      user[key]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const sortedUsers = [...filteredUsers].sort((a, b) => {
    if (!sortConfig.key) return 0;

    const order = sortConfig.direction === "asc" ? 1 : -1;
    const valueA = a[sortConfig.key] || "";
    const valueB = b[sortConfig.key] || "";

    return valueA > valueB ? order : valueA < valueB ? -order : 0;
  });

  const [sites, setSites] = useState([]);

  const getSitesList = async () => {
    try {
      const res = await APICALL("/admin/getAllsites", "post", {});
      if (res?.status) {
        const sitesData = res?.data.map((data) => data);
        setSites(sitesData);
      }
    } catch (error) { }
  };

  useEffect(() => {
    getUsersList();
    getSitesList();
  }, []);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "subscriber",
  });

  console.log("formData", formData);

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const changePassword = (e) => {
    const { value } = e.target;
    setPassword(value);
  };

  const editUser = (user) => {
    setIsEditMode(true);
    setErrors({});
    setCurrentId(user._id);
    setLoading(true);

    setFormData({
      firstName: user?.firstName || "",
      id: user?._id || "",
      lastName: user?.lastName || "",
      email: user?.email || ""
    });
    setLoading(false);
    setShow(true);
  };

  const [loadingButton, setLoadingButton] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setLoadingButton(true);
        const apiData = {
          ...formData,
          role: "subscriber",
          id: isEditMode ? currentId : undefined,
          password: isEditMode ? "oo" : password,
        };

        // const apiUrl = apiBaseURL + "/admin/adminSignup";

        // const response = await fetch(apiUrl, {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify(apiData),
        // });

        // const res = await response.json();

        const res = await APICALL("admin/adminSignup", "Post", apiData);

        if (res.status) {
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            role: "subscriber",
          });
          setPassword(null);
          setErrors({});
          setShow(false);
          getUsersList();
          toastifySuccess(res?.message);
          setLoadingButton(false);
        } else {
          setLoadingButton(false);
          toastifyError(res?.message || "Submission failed");
        }
      } catch (error) {
        setLoadingButton(false);
        toastifyError("Something went wrong!");
      }
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.firstName) newErrors.firstName = "First Name is required";
    if (!formData.lastName) newErrors.lastName = "Last Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!isEditMode) {
      if (!password) newErrors.password = "Password is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const deleteItem = (id, type) => {
    setSelectedId(id);
    setSelectedType(type);
    setDeleteModal(true);
  };

  const handleDeleteSuccess = () => {
    getUsersList();
  };

  const passwordModalShow = (subscriberId) => {
    setPasswordData({
      ...passwordData,
      subscriberId: subscriberId,
    });
    setPasswordModal(true);
  };

  const [passwordModal, setPasswordModal] = useState(false);

  const [passwordData, setPasswordData] = useState({
    subscriberId: "",
    password: "",
  });

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({
      ...passwordData,
      [name]: value,
    });
  };


  const handleChangePassword = async (e) => {
    e.preventDefault();
    try {
      setLoadingButton(true);
      const res = await APICALL("admin/changeSubscriberPassword", "Post", passwordData);
      if (res.status) {
        setPasswordData({
          subscriberId: "",
          password: "",
        });
        setPasswordModal(false);
        getUsersList();
        toastifySuccess(res?.message);
        setLoadingButton(false);
      } else {
        setLoadingButton(false);
        toastifyError(res?.message || "Submission failed");
      }
    } catch (error) {
      setLoadingButton(false);
      toastifyError("Something went wrong!");
    }
  }

  const [buttonText, setButtonText] = useState('Copy Login URL');

  const handleCopy = () => {
    const urlToCopy = window.location.origin + "/admin/login";
    navigator.clipboard.writeText(urlToCopy)
      .then(() => {
        setButtonText('Copied!');
        setTimeout(() => setButtonText('Copy Login URL'), 2000); // Reset text after 2 seconds
      })
      .catch((err) => {
        console.error('Failed to copy URL: ', err);
      });
  };

  return (
    <>
      <Row>
        <Col md={6}>
          <Typography className="top_page" variant="h5">
            Subscribers List
          </Typography>
        </Col>

        <Col md={6} className="text-end">
          <div className="add_new">
            <Button
              className="global_btn mb-3 me-2"
              variant="primary"
              onClick={handleCopy}
            > {buttonText}
            </Button>
            <Button
              className="global_btn mb-3"
              variant="primary"
              onClick={handleShow}
            >
              <i class="fa-solid fa-plus me-2"></i> Add New
            </Button>
          </div>
          <div className="search_table mb-3">
            <input
              type="search"
              placeholder="Search Something"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button>
              <SearchIcon />
            </button>
          </div>
        </Col>
      </Row>
      <Paper className="table_samepattern mt-md-2 ">
        <TableContainer>
          {loading ? (
            <AdminLoader />
          ) : (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    {["firstName", "lastName", "email"].map((key) => (
                      <TableCell key={key}>
                        <span
                          onClick={() => handleSort(key)}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {key.charAt(0).toUpperCase() +
                            key.slice(1).replace(/([A-Z])/g, " $1")}
                          <SwapVert
                            fontSize="small"
                            style={{ marginLeft: "4px" }}
                          />
                        </span>
                      </TableCell>
                    ))}
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedUsers.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row?.firstName}</TableCell>
                      <TableCell>{row?.lastName}</TableCell>
                      <TableCell>{row?.email}</TableCell>
                      <TableCell align="right">
                        <Dropdown className="dropdown-custom">
                          <Dropdown.Toggle as={IconButton} variant="link">
                            <MoreVert />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item to="#" onClick={() => editUser(row)}>
                              <Edit style={{ marginRight: "8px" }} />
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              to="#"
                              onClick={() => deleteItem(row?._id, "subscribers")}
                            >
                              <DeleteOutlineIcon
                                style={{ marginRight: "8px" }}
                              />
                              Delete
                            </Dropdown.Item>
                            <Dropdown.Item
                              to="#"
                              onClick={() => passwordModalShow(row?._id)}
                            >
                              <KeyIcon
                                style={{ marginRight: "8px" }}
                              />
                              Change Password
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={users.length} // Dynamic count based on rows length
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) =>
            setRowsPerPage(parseInt(event.target.value, 10))
          }
        />
      </Paper>

      <Modal
        className="modal-all cutoms-login-artist"
        show={show}
        onHide={handleClose}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditMode ? "Edit Subscriber" : "Add New Subscriber"}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="firstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    isInvalid={!!errors.firstName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.firstName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3" controlId="lastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    isInvalid={!!errors.lastName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.lastName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={isEditMode ? "12" : "6"}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleInputChange}
                    isInvalid={!!errors.email}
                    disabled={isEditMode}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              {!isEditMode ? (
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={password}
                      onChange={changePassword}
                      isInvalid={!!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="global_btn"
              variant="primary"
              type={loadingButton ? "button" : "submit"}
            >
              {loadingButton ? (
                <Spinner size="sm" animation="border" />
              ) : isEditMode ? (
                "Update"
              ) : (
                "Save"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        className="modal-all cutoms-login-artist"
        show={passwordModal}
        onHide={() => setPasswordModal(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Change Password
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleChangePassword}>
          <Modal.Body>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3" controlId="firstName">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Enter Your Password"
                    value={passwordData.password}
                    onChange={handlePasswordChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="global_btn"
              variant="primary"
              type={loadingButton ? "button" : "submit"}
            >
              {loadingButton ? (
                <Spinner size="sm" animation="border" />
              ) : (
                "Save"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>



      {deleteModal && (
        <ConfirmationModal
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          id={selectedId}
          type={selectedType}
          onDeleteSuccess={handleDeleteSuccess}
        />
      )}
    </>
  );
};

export default UserList;
