import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { SwapVert } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import AdminLoader from "../../components/AdminLoader";
import { APICALL } from "../../../helper/api/api";

const NotificationsList = () => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const getNotificationList = async () => {
        setLoading(true);
        try {
            const res = await APICALL('/admin/adminNotifications', 'post', {});
            setLoading(false);
            if (res?.status) {
                setNotifications(res?.data);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        getNotificationList();
    }, []);

    const handleSort = (key) => {
        let direction = "asc";
        if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({ key, direction });
    };

    const filteredNotifications = notifications.filter((site) =>
        Object.keys(site).some((key) =>
            site[key]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const sortedNotifications = [...filteredNotifications].sort((a, b) => {
        if (!sortConfig.key) return 0;
        const order = sortConfig.direction === "asc" ? 1 : -1;
        const valueA = a[sortConfig.key] || "";
        const valueB = b[sortConfig.key] || "";
        return valueA > valueB ? order : valueA < valueB ? -order : 0;
    });

    // Calculate pagination
    const paginatedNotifications = sortedNotifications.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page on rows per page change
    };

    return (
        <>
            <Row>
                <Col md={3}>
                    <div className="bredcrumb_img">
                        <ul className="p-0">
                            <li>
                                <Link to="/admin/dashboard">
                                    <i className="fa-solid fa-house me-2"></i>Home /
                                </Link>
                            </li>
                            <li>
                                <Link className="active" to="/admin/notification">
                                    Notification
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <Typography className="top_page" variant="h5">
                        Notifications List
                    </Typography>
                </Col>

                <Col md={9} className="text-end">
                    <div className="search_table">
                        <input
                            type="search"
                            placeholder="Search Something"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <button>
                            <SearchIcon />
                        </button>
                    </div>
                </Col>
            </Row>

            <Paper className="table_samepattern mt-md-2 mt-3">
                <TableContainer>
                    {loading ? <AdminLoader /> :
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {["Inspector Name", "message"].map((key) => (
                                        <TableCell key={key}>
                                            <span onClick={() => handleSort(key)} style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
                                                {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, " $1")}
                                                <SwapVert
                                                    fontSize="small"
                                                    style={{ marginLeft: "4px" }}
                                                />
                                            </span>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {paginatedNotifications.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{row?.user_name}</TableCell>
                                        <TableCell>{row?.message}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    }
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredNotifications.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
};

export default NotificationsList;
